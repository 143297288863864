<template>
  <div class="custom-card-container" @click.stop="$emit('click', value)">
    <div class="img-box">
      <img :src="value.pic" alt="">
    </div>
    <div class="card-desc">
      <div class="desc">{{ value.title }}</div>
      <div class="divider" />
      <div class="func">
        <span class="detail">查看详情</span>
        <em class="icon el-icon-right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomCard',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-card-container {
  width: 420px;
  cursor: pointer;
  &:hover {
    img {
      transform: scale(1.2);
    }
    .func {
      .icon {
        transform: translateX(5px);
      }
    }
  }
  .img-box {
    width: 100%;
    height: 292px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .3s;
    }
  }
  .card-desc {
    width: 100%;
    border: 1px solid #f1f1f1;
    padding: 8px;
    box-sizing: border-box;
    .desc {
      font-size: 18px;
      height: 50px;
      @include ellipsis(2);
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: #d4d4d4;
      margin: 10px 0;
    }
    .func {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #999;
      .detail {
        font-weight: 200;
        font-size: 16px;
        font-family: "Arial";
      }
      .icon {
        font-weight: bold;
        transition: all .3s;
        font-size: 24px;
      }
    }
  }
}
</style>
