<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-title`">
      {{title}}
    </div>
    <div :class="`${prefixCls}-desc`">
      {{desc}}
    </div>
    <div :class="`${prefixCls}-divide`">
      <div :class="`${prefixCls}-divide-box`"></div>
      <div :class="`${prefixCls}-divide-line`"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'componentsSeparate',
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'dark'
    }
  },
  data () {
    return {
      prefixCls: this.$prefix + '-components-separate'
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -components-separate;
  .#{$prefixCls}{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    &-title{
      font-size: 32px;
      line-height: 2;
    }
    &-desc{
      font-size: 18px;

    }
    &-divide{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 20px 0;
      position: relative;
      &-box{
        width: 20px;
        height: 20px;
        background-color: $primary-color;
        position: absolute;
        top: -10px;
      }
      &-line{
        width: 300px;
        height: 2px;
        background-color: rgb(230, 228, 228);
      }
    }
  }
</style>
