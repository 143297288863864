import WOW from 'wow.js'
export const WowMixin = {
  data () {
    return {
      baseDelay: 0.4
    }
  },
  mounted () {
    const wow = new WOW(
      {
        boxClass: 'wow',
        animateClass: 'animated',
        // offset: 60,
        mobile: true,
        live: true,
        callback: function (box) {
        },
        scrollContainer: '#scrollContainer',
        resetAnimation: true
      }
    )
    wow.init()
  }
}
