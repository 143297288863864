<template>
  <div :class="`${prefixCls}-social`" id="aochenSocial">
    <Separate title="社会责任" desc="SOCIAL RESPONSIBILITY" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div :class="`${prefixCls}-social-content`">
      <custom-card class="card" v-for="item in honorList" :key="item.id" :value="item" />
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import CustomCard from '@/components/CustomCard/index.vue'
import { siteNewsPage } from '@/api/index'
export default {
  name: 'SocialResponsibility',
  data () {
    return {
      prefixCls: this.$prefix + '-company',
      honorList: []
    }
  },
  mixins: [WowMixin],
  components: {
    CustomCard,
    Separate
  },
  created () {
    this.siteNewsPage()
  },
  methods: {
    siteNewsPage () {
      siteNewsPage({ channelId: '14' }, { channelId: 0 }).then(({ data }) => {
        this.honorList = data.data.rows
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -company-social;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $content-width;
  flex-grow: 1;
  &-content{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .card {
      margin: 0 20px 20px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
